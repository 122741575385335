.content_bg{
    background: linear-gradient(to bottom, rgb(238, 243, 246), rgb(217,236,250)); 
}
.content_container{
    display: flex;
  padding: 8vw;
    grid-template-columns: 50% 50%;
    gap:60px;
}
.content_container1{
  display: flex;

  grid-template-columns: 50% 50%;
  gap:60px;
}
.content_container2{
  display: flex;
  padding: 2vw;
  grid-template-columns: 50% 50%;
  gap:60px;
}
.content_text_column {
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .content_text_column1 {
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }
  .content_txt_title{
    margin-top: 5rem;
    font-size: 35px;
    font-weight: 600;
    color: #27589E;
  }
  .content_txt_para{
    
    font-size: 18px;
   
    padding-right: 60px;
  }

  .content_title{
    font-family: "Outfit", Sans-serif;
    font-size: 55px;
    font-weight: 600;
    line-height: 65px;
    color:#000;
    padding-top: 4vw;
    margin-left: 1rem;
}
.content_para{
    font-size: 20px;
   padding: 0rem 0rem 0rem 1rem;
  }
  .content_button{
    background: linear-gradient(to right, #1b75bb, #2b40dd);
    width: 12rem;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 13px;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;

  }
  .content_icon1 {
    font-size: 30px;
    color: black;
    background-color: #146bae;
    padding: 2px;
    border-radius: 50px;
  }
  .content_wapp {
    display: flex;
    gap: 15px;
    margin: 20px;
  }

  .content_wapp_1 {
    display: flex;
    gap: 15px;
    margin: -10px 20px 20px 20px;
  }
  .content_title1{
    font-family: "Outfit", Sans-serif;
    font-size: 55px;
    font-weight: 600;
    line-height: 49px;
    text-align: center;
    color: #27589E;
    margin-top: 6rem;
  }
  .content_tx_description{
    font-family: "Outfit", Sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    text-align: center !important;
  }

  .content-grid{
    gap:10px;
    background-color: #ffffff;
    filter: drop-shadow(
      1px 2px 8px var(--shadow-color)
    );
    border-radius: 25px;
    width: 50%;
    padding: 60px 30px;
    text-align: center;
}

.black.content-grid {
    --shadow-color: hsl(0deg 0% 0% / 0.25);
  }

  .content-grid-title{
    font-size: 25px;
    color: rgb(22, 21, 21);
    font-weight: 700;
  }
  
  .content-grid-description{
    color: #575757;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.3px;
  }
  .content_center_logo{
    display: block;
    margin: 0 auto; 
  }
  .content_service_title{
    font-family: "Outfit", Sans-serif;
    font-size: 55px;
    font-weight: 600;
    line-height: 49px;
    color: #27589E;
   
    text-align: center;
    margin-top: 5rem;
  }

  .content-progress{
    display: flex;
    gap: 2rem;
    margin: 50px 0px 0px 30px;
  }

  .content-progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: 
      radial-gradient(closest-side, white 79%, transparent 80% 100%),
      conic-gradient(#EE4619 90%, #F4F4F4 0);    
  }

  
.content-progress-bar::before {
  content: "90%";
  font-weight: 800;
  font-size: 17px;
}

.content-progress-bar1 {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#EE4619 99%, #F4F4F4 0);    
}

.content-progress-bar1::before {
  content: "99%";
  font-weight: 800;
  font-size: 17px;
}

.content-progress h4{
  font-size: 19px;
  margin-top: 20px;
  font-weight: 600;
}

.content_txt_title1{
 
  font-size: 35px;
  font-weight: 600;
  color: #27589E;
}
.content_txt_para1{  
  font-size: 20px;
 
}
.content_icon_container{
  display: flex;
  gap:10px;
 margin-top: 20px;
 margin-left: 20px;
}
.content_icon{
 
  font-size:30px;
    color: #1b75bb;
}
.content_icon_para{
  font-size: 20px;
}
.content_txt_para2{  
  font-size: 18px;
  padding-right: 60px;
 
}

.content_grid3{
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
   margin: 60px 60px 40px 60px;
    text-align: center;
}
.content_grid3_bg{
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: auto;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);

}

.content_grid3_bg h4{
  /* color: #27589E; */
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  padding:10px 40px 10px 40px;
}
.content_grid3_bg p{
  padding: 0px 20px 20px 20px;
  text-align: center;
  font-size: 18px;
}

.content-grid3_img{
  padding-top: 40px;
}
.content_tx_description1{
 
  font-size: 25px;
  font-weight: 400;
  line-height: 33px;
  padding: 20px 80px 0 80px;
  letter-spacing: 0.3px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .content_wapp{
    flex-direction: column;
  }

  .content_wapp_1{
    flex-direction: column;
    margin-top: 10px;
  }
  .content_container, .content_container1, .content_container2 {
      display: block; /* Stack the elements vertically */
      padding: 20px;
  }

  .content_text_column, .content_text_column1 {
      min-width: 100%; /* Make the text columns take full width on mobile */
      margin-bottom: 20px;
  }

  .content_txt_title, .content_txt_title1 {
      font-size: 28px; /* Reduce font size for mobile */
      margin-top: 2rem;
  }

  .content_button {
      width: 50%;
      padding: 15px 0;
      font-size: 16px;
      margin-top: 20px;
  }

  .content_title, .content_title1 {
      font-size: 40px;
      line-height: 45px;
      padding-top: 2rem;
  }

  .content_para, .content_txt_para, .content_txt_para1, .content_txt_para2 {
      font-size: 16px;
      padding: 0 20px;
  }

  .content-grid {
      width: 100%; /* Make grid elements take full width */
      padding: 30px 15px;
  }

  .content_grid3 {
      grid-template-columns: 1fr; /* Stack the grid elements */
      margin: 20px;
  }

  .content_progress_bar, .content_progress_bar1 {
      width: 80px;
      height: 90px;
  }

  .content-progress h4 {
      font-size: 16px;
      margin-top: 10px;
  }
  .content_container1 img{
    width: 100%;
    height: auto;
  }
  .content_container2 img{
    width: 100%;
    height: auto;
  }
  .content_banner_img{
    width: 500px;
    /* height: auto; */
    height: 500px;
  }
}

@media screen and (max-width: 480px) {
  .content_txt_title, .content_txt_title1 {
      font-size: 24px;
      margin-top: 1rem;
  }

  .content_title, .content_title1 {
      font-size: 30px;
      line-height: 40px;
  }

  .content_txt_para, .content_txt_para1, .content_txt_para2, .content_para {
      font-size: 14px;
      padding: 0 10px;
  }

  .content_grid3 {
      margin: 10px;
  }

  .content-grid {
      padding: 20px 10px;
  }

  .content_button {
    width: 60%;
      font-size: 14px;
      padding: 10px;
  }

  /* .content_progress_bar, .content_progress_bar1 {
      width: 50px;
      height: 50px;
  } */
}

@media (max-width: 768px) {
  .content-progress {
    gap: 1rem; /* Reduce spacing between items */
    margin: 20px 0px 0px 10px; /* Adjust margins for smaller screens */
  }

  .content-progress-bar, .content-progress-bar1 {
    width: 80px; /* Reduce size for smaller screens */
    height: 60px;
  }

  .content-progress-bar::before, .content-progress-bar1::before {
    font-size: 14px; /* Adjust font size */
  }

  .content-progress h4 {
    font-size: 16px; /* Adjust heading size */
  }
}

@media only screen and (max-width:768px) and (min-width:320px) {
  
  .content_banner_img{
    width:100%;
    height: auto;
  }
}

  
