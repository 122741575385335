.service {
    text-align: center;
    max-width: 100%;
    height: auto;
    background-color: #EBEBEB;
    border-radius: 80px 80px 80px 80px;
    padding: 0px;
  }
  
  .title {
    padding: 20px 20px;
    color: #27589E;
    font-size: 40px;
    font-weight: 600;
  }
  
  .subtitle-homeser {
    padding: 0px 30px 0px 0px;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 50px;
  }
  
  /* Responsive grid for cards */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  } 
  
  .card {
    background-color: #1C1C2D;
    border-radius: 40px;
    margin-bottom: 20px;
    color: white;
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: columinfn;
    align-items: center;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    transition: ease-out 0.3s;
  }
  

  
  .card img {
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .card:hover {
    background: linear-gradient(to bottom, #2B40DD, #1B75BB);
  }
  
  .title_head {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
        /* margin-bottom: 6px; */
  }

  .title_head1{
    margin-top: 15px;
    margin-left: 15px;
    text-align: left;
  }
  
  .descrip_para {
    font-size: 15px;
    text-align: center;
    padding: 10px;
  }
  
  .divider {
    background-color: rgb(30, 29, 29);
    width:100%;
    margin: 10px 0;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .heading {
      font-size: 40px;
      margin-top: 50px;
      margin-left: 20px;
    }
  
    .bannerimage {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
  
    .icon-button {
      margin-right: 0;
      width: 100%;
    }
  
    .title {
      font-size: 30px;
    }
  
    .subtitle {
      font-size: 18px;
    }
  
    .card {
       
      height: auto;
      margin-bottom: 20px;
    }
  
    /* .grid-container {
      gap: 15px;
    } */
  
    .title_head {
      font-size: 18px;
    }
  
    .descrip_para {
      font-size: 12px;
    }
    .divider {
      background-color: #1B75BB;
      width:100%;
      margin: 10px 0px 0px 10px;
    }
  }
  
  /* Extra small devices (phones) */
  @media (max-width: 576px) {
    .heading {
      font-size: 32px;
      margin-left: 10px;
    }
  
    .subtitle {
      font-size: 16px;
    }
  
    .card img {
      height: 60px;
      width: 60px;
    }
  
    .card{
     
      margin-bottom: 20px;
    }
    .title_head {
      font-size: 20px;
      text-align: center;
    }
  
    .descrip_para {
      font-size: 12px;
    }
  
    .icon-button {
      font-size: 14px;
      padding: 10px 15px;
      margin-right: 0;
      width: 100%;
    }
    .divider {
      background-color: rgb(30, 29, 29);
      width:100%;
      margin: 10px 0;
    }
  }
  
  @media (max-width: 380px) {
    .heading {
      font-size: 32px;
      margin-left: 10px;
    }
  
    .subtitle {
      font-size: 16px;
    }
  
    .card img {
      height: 60px;
      width: 60px;
    }
  
    .card{
      margin-bottom: 20px;
    }
    .title_head {
      font-size: 18px;
    }
  
    .descrip_para {
      font-size: 12px;
    }
  
    .icon-button {
      font-size: 14px;
      padding: 10px 15px;
      margin-right: 0;
      width: 100%;
    }
    .divider {
      background-color: #1B75BB;
      width:100%;
      margin: 10px 0;
    }
  }
  
  