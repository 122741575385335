.contact-form-land {
    width: 75%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0 60px;
    font-family: outfit, sans-serif;
  }
  
  .form-row1 {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
  }
  
  .form-row1 input,
  .form-row1 textarea,.form-row1 select {
    width: 100%;
    padding: 15px;
    border: 1px solid #F0F0F0;
    border-radius: 20px;
    font-size: 20px;
    background-color: #F0F0F0;
  }
  
  textarea {
    height: 120px;
    resize: none;
  }

  .sf_land_icon_cont{
    font-size: 13px;
  }
  
  .submit-button {
    background-color: #0D65C2; /* Replace with your theme color */
    color: white;
    border: none;
    width: 10rem;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    display: block; /* Ensures proper block alignment */
  margin-left: auto;
  }
  
  .submit-button:hover {
    background-color: #0D65C2; /* Darker shade for hover */
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #0D65C2; /* Focus color */
  }

  /* Styling the select dropdown */
select {
  -webkit-appearance: none; /* Removes the default arrow in Webkit browsers */
  -moz-appearance: none;    /* Removes the default arrow in Firefox */
  appearance: none;         /* Standardizes appearance in other browsers */
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  color: #333;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230D65C2' width='24px' height='24px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 40px center;
  background-size: 40px;
}

select:focus {
  outline: none;
  border: 1px solid #0D65C2;
}




@media (max-width: 768px) {
  .form-row1 {
      flex-direction: column; /* Stack fields vertically on smaller screens */
      gap: 15px; /* Reduced spacing for compact design */
      margin-bottom: 15px;
  }

  .form-row1 input,
  .form-row1 textarea,
  .form-row1 select {
      font-size: 16px; /* Smaller font size for compact display */
      padding: 12px;
  }

  .submit-button {
      font-size: 16px;
      padding: 12px;
      width: 100%; /* Ensures the button spans the full width */
  }
}

@media (max-width: 480px) {
  .form-row1 {
      gap: 10px; /* Further reduced spacing for extra small screens */
      margin-bottom: 10px;
  }

  .form-row1 input,
  .form-row1 textarea,
  .form-row1 select {
      font-size: 14px; /* Smaller font size for extra compact display */
      padding: 10px;
  }

  .submit-button {
      font-size: 14px;
      padding: 10px;
  }
}
