  .ios_container_bg {
    background: linear-gradient(to bottom, rgb(238, 243, 246), rgb(217, 236, 250));
}
.ios{
    width: 100%;
}

.ios_banner {
    display: flex;
    grid-template-columns: 50% 50%;
}

.ios_banner1 {
    display: flex;
    grid-template-columns: 70% 30%;
    gap: 20px;
}

.ios_banner2 {
    display: flex;
    grid-template-columns: 50% 50%;
    gap: 20px;
}

.ios_banner_img {
    padding: 20px;
    height: 600px;
    width: 600px;
    margin-top: 30px;
    margin-bottom: 60px;
}

.ios-icon {
    color: #1b75bb;
    font-size: 2.5vw;
    font-weight: 900;
}

.expertise-ios {
    display: block;
    background-color: #F4F9FF;
    padding: 20px;
}

.expertise-h-ios {
    font-size: 2.3vw;
    display: flex;
    gap: 5px;
    font-weight: 600;
    margin-bottom: 10px;
}

.expertise-con {
    font-size: 1.3vw;
}

#expertise-last {
    margin: 0 20px 40px;
    height: auto;
}

.ios-service {
    padding: 2rem 9rem;
}

.ios-service-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.ios-service-title {
    font-size: 55px;
    font-weight: 700;
}

.ios-service-para {
    font-size: 20px;
    margin-bottom: 40px;
}

.ios-service-img {
    width: 300px;
    height: 250px;
    margin-left: 150px;
    margin-bottom: 60px;
}

.ios-head-line {
    margin: 80px auto 90px;
    text-align: center;
    font-size: 4vw;
    color: #27589E;
    font-weight: 700;
}

.ios-choose-para {
    text-align: center;
    font-size: 2vw;
    margin: 0px 120px 30px;
}

.ios_title2{
    font-family: "Outfit", Sans-serif;
      font-size: 60px;
      font-weight: 600;
      line-height: 49px;
      text-align: center;
      color: #27589E;
      margin-top: 4rem;
   } 

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
    .ios_banner {
        display: block;
    }

    .ios_banner1,
    .ios_banner2 {
        display: block;
    }

    .ios_banner_img {
        height: auto;
        width: 100%;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .ios-icon {
        font-size: 5vw; /* Increase icon size for mobile */
    }

    .expertise-h-ios {
        font-size: 4vw; /* Adjust title size */
        flex-direction: column;
        align-items: center;
    }

    .expertise-con {
        font-size: 3vw; /* Adjust content font size */
    }

    #expertise-last {
        margin: 0 10px 20px;
    }

    .ios-service {
        padding: 1rem 1.5rem;
    }

    .ios-service-container {
        grid-template-columns: 1fr; /* Show services in a single column */
    }

    .ios-service-title {
        font-size: 8vw; /* Increase title font size */
        text-align: center;
    }

    .ios-service-para {
        font-size: 4vw; /* Adjust paragraph size */
        text-align: center;
    }

    .ios-service-img {
        width: 80%; /* Make images responsive */
        height: auto;
        margin: 0 auto 30px;
    }

    .ios-head-line {
        font-size: 8vw; /* Adjust headline size */
        margin: 50px auto 30px;
    }

    .ios-choose-para {
        font-size: 4vw; /* Adjust font size for mobile */
        margin: 0 20px 20px;
    }

    .ios_title2{
        margin-top: -80px;
        font-size: 34px;
        line-height: 1.2em;
        width: 100%;
        padding: 0px 10px 0px 10px;
}
}

@media only screen and (max-width:480px) and (min-width:320px) {
    .ios_title2{
        margin-top: -80px;
        font-size: 34px;
        line-height: 1.2em;
        width: 100%;
        padding: 0px 10px 0px 10px;
}
}
