* {
    margin: 0;
    padding: 0;
}

.lateral-section {
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom, rgb(238, 243, 246), rgb(217,236,250)); 
    padding: 40px;
    justify-content: space-evenly;
}

.lateral-text {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2em;
}

.lateral-text h1 {
    font-size: 2.2em;
    color: #27589E;
    font-weight: 700; 
}

.lateral-text h3 {
    font-size: 2.2em;
    color: #27589E;
    margin: 10px 0;
    font-weight: 700;
}

.lateral-text p {
    line-height: 1.6;
    margin: 10px 0;
    color: black;
    text-align: justify;
    font-size: larger;
}

.sec1{
    display: grid;
    grid-template-columns: auto auto;
    gap: 69px;
    padding: 20px 70px 60px;

}
.sec2{
    display: grid;
    grid-template-columns: auto auto;
    gap: 69px;
    padding: 20px 70px;

}

.lateral-image {
    flex: 1 0 0;
    height: 585px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 4px solid #1B75BB;
    border-radius: 0px 155px; 
    margin-left: 20px; 
}

.lateral-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}

.lateral-image:hover img {
    transform: scale(1.2);
}

.connect-button {
    margin-top: 20px;
    padding: 15px 20px;
    font-size: 1em;
    color: rgb(255, 255, 255);
    background-color: #1B75BB;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
}

.connect-button:hover {
    background: linear-gradient(32deg, #3030e8 20%, #1b75bb 100%);
    transform: translateY(-2px);
}

.section1 {
    padding: 2px 0px;
    background-color: #fff;
}

.section1 .lateral-text {
    padding: 9px;
    margin-bottom: 20px;
}

.section1 .lateral-text h1 {
    font-size: 2.5em;
    color: #27589E;
    margin-bottom: 20px;
    text-align: center;

}

.section1 .lateral-text h2 {
    font-size: 1.8em;
    color: #1B75BB;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.section1 .lateral-text h2 svg {
    margin-right: 10px;
}

.section1 .lateral-text p {
    font-size: 1.2em;
    color: #333;
    line-height: 1.6;
    margin-bottom: 30px;
}

.image2 {
    width: 100%;
    height: 27vw;
    max-width: 645px;
    border-radius: 155px 0px;
   
}
.image3 {
    width: 100%;
    height: 30vw;
    max-width: 600px;
    border-radius: 0px 155px;
    display: block;
    margin-left: auto;
    margin-right: 0px;
}
/* 
section {
    padding: 0px 0px;
    background-color: #fff;
} */


.lateral-text {
    padding: 20px;
    margin-bottom: 20px;
}

.lateral-text h2 {
    font-size: 1.8em;
    color: #1B75BB;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.lateral-text h2 svg {
    margin-right: 8px;
}

.lateral-text p {
    font-size: 1.1em;
    color: #333;
    line-height: 1.6;
    margin-bottom: 30px;
}


.section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .lateral-text1 {
    text-align: justify;
}

  
  .lateral-text1 h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .lateral-text1 p {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
  }
  
  .lateral-text2{
    text-align: justify;
  }

  .lateral-text h2{
    font-size: 1.5em;
  }

  .lateral-text2 p {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
  }

  .head2 h2{
    font-size: 2.6em;
    color: #27589E;
    margin: 20px;
    text-align: center;
    font-weight: 700;
  }

  .logoitem1 {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -356px 6px 327px 6px;
}

@media (max-width: 1024px) {

    .lateral-section {
        flex-direction: column; 
        padding: 20px;
    }

    .lateral-text {
        font-size: 1.1em;
        padding: 10px;
    }

    .lateral-text h1 {
        font-size: 2em; 
    }

    .lateral-text h3 {
        font-size: 1.3em;
    }

    .lateral-text p {
        font-size: 1em;
    }

    .lateral-image {
        height: 400px; 
        margin-left: 0;
        margin-top: 20px;
    }

    .sec1, .sec2 {
        grid-template-columns: 1fr; 
        gap: 20px;
    }

    .sec1, .sec2 {
        padding: 10px;
    }

    .connect-button {
        width: 100%;
        padding: 10px;
        font-size: 0.9em;
    }

    .image2, .image3 {
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .logoitem1 {
        margin: 0;
    }

}

@media (max-width: 768px) {

    .lateral-section {
        padding: 15px;
    }

    .lateral-text h1 {
        font-size: 1.8em;
    }

    .lateral-text h3 {
        font-size: 1.2em;
    }

    .lateral-text p {
        font-size: 1em;
    }

    .lateral-image {
        height: 300px;
    }

    .sec1, .sec2 {
        grid-template-columns: 1fr; 
        gap: 20px;
    }

    .connect-button {
        width: 100%;
        font-size: 0.9em;
        padding: 12px;
    }

    .image2, .image3 {
        height: auto;
    }

    .section1 .lateral-text {
        padding: 15px;
    }

    .logoitem1 {
        margin: 0;
    }

    .lateral-text1 h2 {
        font-size: 1.3em;
    }

    .lateral-text1 p {
        font-size: 0.9em;
    }

    .lateral-text2 h2 {
        font-size: 1.3em;
    }

    .lateral-text2 p {
        font-size: 0.9em;
    }

    .head2 {
        font-size: 1.4em;
        padding: 10px;
    }
}


@media (max-width: 480px) {
    .lateral-section {
        padding: 10px;
    }

    .lateral-text {
        font-size: 1em;
    }

    .lateral-text h1 {
        font-size: 1.6em;
    }

    .lateral-text h3 {
        font-size: 1.1em;
    }

    .lateral-text p {
        font-size: 0.9em;
    }

    .lateral-image {
        height: 250px; 
    }

    .sec1, .sec2 {
        grid-template-columns: 1fr; 
        gap: 10px;
    }

    .sec1, .sec2 {
        padding: 10px;
    }

    .connect-button {
        width: 100%;
        padding: 10px;
    }

    .image2, .image3 {
        height: auto;
    }

    .section1 .lateral-text h1 {
        font-size: 1.8em;
    }

    .section1 .lateral-text h2 {
        font-size: 1.5em;
    }

    .section1 .lateral-text p {
        font-size: 1em;
    }

    .logoitem1 {
        margin: 0;
    }
}