.seo_banner_bg{
    background: linear-gradient(to bottom, rgb(238, 243, 246), rgb(217,236,250)); 
}
.seo_container{
    display: flex;
    grid-template-columns: 50% 50%;
    gap:60px;
    padding: 70px;
}
.seo_text_column {
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;
  }
  .seo_banner_title{
    font-family: "Outfit", Sans-serif;
    font-size: 55px;
    font-weight: 600;
    line-height: 65px;
    /* color:#27589E; */
  }
  .seo_banner_descr{
    font-size: 18px;
  }

  .seo_title{
    font-family: "Outfit", Sans-serif;
        font-size: 55px;
        font-weight: 600;
        line-height: 49px;
        text-align: center;
        color: #27589E;
        margin-top: 8rem;
  }

  .seo_txt_description{
    font-family: "Outfit", Sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    text-align: center !important;
    padding: 0px 60px 0px 60px;
  }
  .seo_wapp {
    display: flex;
    gap: 15px;
    margin: 20px;
  }

  .staff_card_head{
    font-size: 2.5rem;
  }

  .seo-grid{
    gap:10px;
    background-color: #ffffff;
    filter: drop-shadow(
      1px 2px 8px var(--shadow-color)
    );
    border-radius: 25px;
    width: 50%;
    padding: 60px 30px;
    text-align: center;
}

.black.seo-grid {
    --shadow-color: hsla(0, 0%, 0%, 0.155);
  }

  .seo-grid-title{
    font-size: 25px;
    color: rgb(22, 21, 21);
    font-weight: 700;
    padding-top: 10px;
  }

  .seo-grid-description{
    color: #575757;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.3px;
  }
  .seo_center_logo{
    display: block;
    margin: -24px auto; 
  }

  .seo_service_title{
    font-family: "Outfit", Sans-serif;
    font-size: 55px;
    font-weight: 600;
    line-height: 49px;
    color: #27589E;
   margin-bottom: 5rem;
    text-align: center;
    margin-top: 5rem;
  }
  .seo_ser_container{
    background: linear-gradient(to bottom,  rgb(255, 255, 255), rgb(199, 230, 253));
    width: 100%;
    height: auto;
    padding-bottom: 10%;
  }
  .seo_ser{
    display: flex;
    margin: 100px 0px 0px 0px;

  }
  .seo_ser_img{
    width: 550px;
    height: 380px;
    border-radius: 30px;
   margin-left: 5%;
  }
  .seo_ser_img1{
 /* float: right;  */
 width: 550px;
    height: 380px;
    margin-left: 50vw;
    border-radius: 30px;
    margin-top: 70px;
  }
  .seo_ser_img2{
    /* float: right;  */
    width: 550px;
       height: 330px;
       margin-left: 40vw;
       border-radius: 30px;
       margin-top: 70px;
     }
  .seo_ser_bg{
    background-color: #ffffff;
    width: 44%;
    height: 320px;
    border-radius: 50px;
    margin-left: -170px;
    margin-top: 80px;
    
  }
  .seo_ser1{
    position: relative;
    width: 100%;
    max-width: 87%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end; 
    
  } 
  .seo_ser_bg1{
    position: absolute;
    top: 75%;
    left: 40%;
    transform: translate(-50%, -50%);
    border-radius: 40px;
    background-color: #ffffff; /* Optional background overlay */
    padding: 20px;
    /* text-align: center; */
    width: 50%; 
  
    
  }
  .seo_ser_title{
    padding-top: 40px;
    padding-left: 50px;
    font-size: 30px;
    font-weight: 600;
  }
  .seo_ser_para{
    padding: 10px 50px  0px 50px;
    font-size: 20px;
  }

  .seo_ser_title1{
    padding-top: 40px;
    padding-left: 50px;
    font-size: 30px;
    font-weight: 600;
  }
  .seo_ser_para1{
    padding: 10px 50px  0px 50px;
    font-size: 20px;
  }

  .seo_container{
    display: flex;
    grid-template-columns: 50% 50%;
    gap:30px;
    padding: 70px;
}

.seo_choose_img{
  width: 50%;
  height: 50%;
  border-radius: 20px 280px 280px 20px;
  margin-top: -40px;
}
.seo_text_column {
  flex: 1;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}
.seo_accordion{
  margin-top: -190px;
}

/* General styles for smaller screens */
@media only screen and (max-width: 768px) {

  .seo_container {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .seo_text_column {
    width: 100%;
    min-width: unset; /* Remove min-width for mobile */
  }

  .seo_banner_title {
    font-size: 40px; /* Smaller font size for mobile */
    line-height: 48px;
  }

  .seo_banner_descr {
    font-size: 16px;
  }

  .seo_title {
    font-size: 35px; /* Adjust title font size */
    line-height: 45px;
    margin-top: 4rem;
  }

  .seo_txt_description {
    font-size: 18px;
    padding: 0 30px; /* Reduce padding on mobile */
  }

  .seo_service_title {
    font-size: 40px; /* Smaller font size for mobile */
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .seo_ser_container {
    padding-bottom: 20%;
  }

  .seo_ser {
    flex-direction: column;
    margin: 50px 0;
  }

  .seo_ser_img {
    width: 100%;
    height: auto; /* Adjust image size */
    margin-left: 0;
    border-radius: 20px;
  }

  .seo_ser_img1, .seo_ser_img2 {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 30px;
    border-radius: 20px;
  }

  .seo_ser_bg {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 30px;
    border-radius: 20px;
  }

  .seo_ser_title, .seo_ser_title1 {
    font-size: 25px;
    padding-left: 20px;
  }

  .seo_ser_para, .seo_ser_para1 {
    font-size: 16px;
    padding: 10px 20px;
  }

  .seo_choose_img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    margin-top: 10px;
  }

  .seo_wapp {
    flex-direction: column;
    margin: 25px 0;
    gap: 10px;
  }

  .seo-grid {
    width: 100%;
    padding: 30px 20px;
    border-radius: 20px;
  }

  .seo_grid-title {
    font-size: 20px;
  }

  .seo-grid-description {
    font-size: 16px;
  }

  .seo_ser_bg1 {
    position: relative; /* Change to relative to avoid overlapping on mobile */
    top: auto;
    left: auto;
    transform: none; /* Remove translate for mobile */
    width: 100%; /* Full width on mobile */
    padding: 15px; /* Adjust padding for mobile */
    border-radius: 20px; /* Smaller border-radius on mobile */
  }

  .seo_accordion {
    margin-top: 0; /* Adjust accordion margin */
  }

  .seo_ser1 {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    justify-content: center; /* Center the content */
    width: 100%;
    max-width: 100%; /* Remove max-width restriction for mobile */
  }
}

/* Extra small devices (e.g., phones smaller than 480px) */
@media only screen and (max-width: 480px) {

  .seo_banner_title {
    font-size: 30px;
    line-height: 40px;
  }

  .seo_title {
    font-size: 28px;
    line-height: 38px;
    margin-top: 2rem;
  }

  .seo_txt_description {
    font-size: 14px;
    padding: 0 20px;
  }

  .seo_service_title {
    font-size: 30px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .seo_ser_img, .seo_ser_img1, .seo_ser_img2, .seo_choose_img {
    border-radius: 10px;
  }

  .seo_ser_title, .seo_ser_title1 {
    font-size: 20px;
    padding-left: 10px;
  }

  .seo_ser_para, .seo_ser_para1 {
    font-size: 14px;
    padding: 10px;
  }

  .seo-grid-title {
    font-size: 18px;
  }

  .seo-grid-description {
    font-size: 14px;
  }
}
