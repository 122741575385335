.client-logo {
    background-color: white;
    padding: 20px 0;
  }
  
  .client {
    margin-top: 80px;
    font-weight: 600;
    text-align: center;
    font-size: 4vw;
  }
  
  .logo {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @keyframes slid {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-30%);
    }
  }
  
  .logo-slid, .logo-slid-lp {
    display: inline-block;
    animation: 14s slid  linear infinite;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(3890 * 40px);
  }
  
  .logo:hover .logo-slid {
    animation-play-state: paused;
  }

  .logo:hover .logo-slid-lp {
    animation-play-state: paused;
  }

  .logo-slid img {
    height: 150px;
    margin: 0 20px;
    transition: transform 0.3s ease;
  }
  .logo-slid-lp img {
    margin: 0 40px;
    transition: transform 0.3s ease;
  }
  
  .logo-slid img:hover {
    transform: scale(1.1);
  }
  .logo-slid-lp img:hover {
    transform: scale(1.1);
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .logo-slid img {
      height: 120px;
      margin: 0 15px;
    }
    .logo-slid-lp img {
      height: 50px;
      margin: 0 15px;
    }
  }
  
  @media (max-width: 576px) {
    .logo-slid-lp img {
      height: 25px;
      margin: 0 10px;
    }
    .logo-slid img {
      height: 100px;
      margin: 0 10px;
    }
  }
  