
.about-banner {
    background-color:#ffffffc1 ;
    padding: 14px;
    text-align: center; 
    position: relative;
  }
  
  .about-title {
    position: absolute;             
    top: 56%;                     
    left: 50%;                     
    transform: translate(-50%, -50%);
    font-size: 2.5em;           
    color: #bababa;               
    text-align: center;          
    background-color: none;
    padding: 10px 20px;          
    z-index: 1;                   
    transition: color 1s ease;  
  }
  
  .about-title:hover {
    color: #1B75BB;              
  }

  .aboutusimg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 0; 
    margin: 0;
  }
  
  .aboutusimg img {
    max-width: 100%; 
    height: auto; 
    border-radius: 5px; 
    transition: transform 0.2s ease;
    will-change: transform;
    transform-origin: center; 
    display: block;
  }
  
  
  
  .aboutusimg:hover img {
    transform: rotate(10deg) scale(1.05); 
  }
  
  .aboutusimg:hover img {
    transform: rotate(360deg) scale(1.05); 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .aboutusimg:hover img {
    animation: spin 2s ease forwards; 
  }
  
  
  .aboutusimg:hover::after {
    opacity: 1; 
  }
  

  
  .heading_about {
    font-size: 3em;
    color: #1B75BB; 
    text-align: center;
    margin: 20px 0;
    box-sizing: border-box;
  }

  .content_about{
    font-family: "Outfit", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: 20px;
  }
  
  