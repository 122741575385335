/* /* General Styles */
.story {
    width: 100%;
    padding: 20px;
}

/* Image styles */
.story-img {
    width: 90%;
    height: auto;
    margin-top: 20px;
    padding-left: 20px;
}

/* Title and description */
.story-title {
    font-size: 45px;
    color: #1B75BB;
    font-weight: 600;
    padding: 0 15px;
    margin-top: 40px;
}

.story-des {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 1.6;
}

/* Box and journey styles */
.box1 {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: auto;
    width: 90%;
    margin: -50px auto;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
}

.box1_head {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.box {
    background-color: #E1FFFD;
    padding: 10px;
    border-radius: 30px;
}

.story-icon {
    font-weight: 900;
    font-size: 35px;
    color: #1B75BB;
}

.story-para {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.story-para1 {
    font-size: 14px;
    margin-top: -10px;
    text-align: center;
}

/* Counters */

.counter{
    margin-top: 120px;
}
.count {
    text-align: center;
    
}

.count-title {
    color: #1B75BB;
    font-size: 20px;
    font-weight: 700;
}
.count-des2{
    margin-bottom: 40px;
    
}

.count2 {
    font-size: 35px;
    font-weight: 700;
}

.count-title2 {
    font-size: 20px;
    font-weight: 700;
    color: #1B75BB;
}

/* Divider */
.divider-custom {
    background-color: #1B75BB;
    height: 2px;
    width: 100%;
  
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .story-title {
        font-size: 35px;
        padding: 0 10px;
        margin-top: 20px;
    }

    .story-des {
        font-size: 14px;
        padding: 0 10px;
    }

    .story-img {
        margin: 20px auto;
        width: 100%;
    }

    .box1 {
        margin-top: -40px;
        width: 70%;
        height: auto;
    }

    .story-para {
        font-size: 18px;
    }

    .story-para1 {
        font-size: 12px;
    }

    .count2 {
        font-size: 28px;
    }

    .count-title {
        font-size: 18px;
    }
}
