  /* css for container 1 */

.sf_land_bg{
    background: radial-gradient(circle at bottom center, #0682c549, transparent 60%);
}

.sf_land_container{
    display: grid;
    padding: 3vw 8vw 0vw 8vw;
    grid-template-columns: 50% 50%;
    padding-bottom: 4rem;
    
}

.span1{
  color: #0D65C2;
}

  .sf_land_title{
    font-family: "Outfit", Sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 70px;
    color: #111;
    padding-top: 4vw;
    margin-left: 1rem;
    margin-bottom: 4rem;
}

.sf_land_image{
  max-width: 100%;
  min-width: 150px;
  height: 657px;
  width: 450px;
  margin-left: 125px;   
}

.sf_land_para{
  font-size: 28px;
  padding: 0rem 6rem 0rem 1rem;
  margin-bottom: 3rem;
   
  }

  .sf_land_icon{
    font-size: 20px;
  }

 

  .sf_land_button{
    background-color: #0D65C2;
    color: #ffffff;
    padding: 10px 25px;
    border: none;
    border-radius: 50px;
    margin-left: 1rem;
    font-size: 24px;
    margin-top: 20px;
  }

  @media (max-width: 1200px) {
    .sf_land_container {
      grid-template-columns: 1fr;
      padding: 3vw 4vw;
    }
  
    .sf_land_image {
      height: auto;
      width: 40%;
      margin: 0 auto;
    }
  
    .sf_land_title {
      font-size: 40px;
      line-height: 60px;
      margin-left: 0;
      text-align: center;
    }
  
    .sf_land_para {
      font-size: 20px;
      padding: 0 2rem;
      text-align: center;
    }
  
    .sf_land_button {
      display: block;
      margin: 20px auto;
    }
  }
  
  @media (max-width: 768px) {
    .sf_land_container {
      padding: 2vw 2vw;
    }
  
    .sf_land_image {
      height: auto;
      width: 80%;
      margin: 0 auto;
    }
  
    .sf_land_title {
      font-size: 32px;
      line-height: 50px;
    }
  
    .sf_land_para {
      font-size: 18px;
      padding: 0 1rem;
    }
  
    .sf_land_button {
      font-size: 20px;
      padding: 8px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .sf_land_image {
      width: 70%;
      height: auto;
    }
  
    .sf_land_title {
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .sf_land_para {
      font-size: 16px;
      padding: 0 1rem;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .sf_land_button {
      font-size: 18px;
      padding: 6px 15px;
    }
  }

  /* css for container 2 */

  .sf_bg{
    background: #0D65C2;
  }
  .sf_right_image{
    max-width: 748px;
    min-width: 250px;
    margin-left: 100px;
    width: 391px;
    height: 383px;
  }
  .sf_right_title{
    color: #ffffff;
    font-weight:500;
    font-size: 48px;
    line-height: 93px;
  }
  .sf_right_para{
    color: #ffffff;
    font-size: 28px;
    margin-top: 30px;
    padding-right: 4rem;
  }

  .sf_right_button{
    background-color: #ffffff;
    color: #0D65C2;
    font-size: 24px;
    padding: 10px 45px;
    border: none;
    border-radius: 50px;
    margin-top: 30px;
    font-weight: 500;
  }


  .sf_right_icon{
    color: #0D65C2;
    font-size: 20px;
    margin-top: -4px;
  }

  /* .sf_right_button1{
    background-color: #ffffff;
    color: #0D65C2;
    font-size: 22px;
    max-width: 200px;
    border: none;
    border-radius: 50px;
    margin-top: 20px;
    font-weight: 500;
  } */

  @media (max-width: 1200px) {
    .sf_bg {
      padding: 3rem 4rem;
      text-align: center;
    }
  
    .sf_right_image {
      width: 50%;
      height: auto;
      margin: 0 auto;
    }
  
    .sf_right_title {
      font-size: 36px;
      line-height: 70px;
    }
  
    .sf_right_para {
      font-size: 20px;
      padding-right: 2rem;
    }
  
    .sf_right_button {
      font-size: 20px;
      padding: 8px 35px;
    }
  }
  
  @media (max-width: 768px) {
    .sf_bg {
      padding: 2rem 2rem;
    }
  
    .sf_right_image {
      width: 60%;
      margin: 0 auto;
    }
  
    .sf_right_title {
      font-size: 28px;
      line-height: 50px;
    }
  
    .sf_right_para {
      font-size: 18px;
      margin-top: 20px;
      padding-right: 1rem;
    }
  
    .sf_right_button {
      font-size: 18px;
      padding: 6px 25px;
    }
  }
  
  @media (max-width: 480px) {
    .sf_right_image {
      width: 70%;
      height: auto;
    }
  
    .sf_right_title {
      font-size: 24px;
      line-height: 35px;
      margin: 10px 0 0;
    }
  
    .sf_right_para {
      font-size: 16px;
      margin-top: 15px;
      padding: 0;
    }
  
    .sf_right_button {
      font-size: 16px;
      padding: 5px 20px;
      margin-top: 20px;
    }
  }

  /* css for container 3 */

  .sf_growth{
    max-width: 100%;
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    margin-top: 80px;
    padding: 0 222px;
  }

  .sf_growth_para{
    text-align: center;
    font-size: 30px;
   
  }
  .sf_growth span{
    color: #0D65C2;
  }
  /* .sf_arrow{
    width: 320px;
    position: absolute;
    margin-top: 130px;
    left: 480px;
   
  }
  .sf_arrow_left{
    width: 320px;
    position: absolute;
    margin-top: -30px;
    left: 350px;
  } */
  .sf_growth_clo1{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 170px;
    margin-right: 170px;
    margin-top: 20px;

  }
  .sf_growth_clo{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 170px;
    margin-right: 170px;
    margin-top: 50px;


  }
  .sf_growth_bg{
    background-color: #F4F4F4;
    border-radius: 30px;
    margin: 15px;

  }
  .sf_growth_bg h4{
    color: #0D65C2;
    padding: 40px 0px 10px 0px;
    font-size: 30px;
    font-weight: 600;
  }
  .sf_growth_bg p{
    font-size: 24px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
  }

  .divider-sf{
    background-color:#000000;
    max-width:  80%;
    margin: 60px auto 0;
    height: 3px;  
}

@media (max-width: 1200px) {
  .sf_growth {
    font-size: 48px;
    margin-top: 60px;
  }

  .sf_growth_para {
    font-size: 24px;
    margin-top: 20px;
  }

  .sf_growth_clo1,
  .sf_growth_clo {
    grid-template-columns: repeat( 1fr);
    margin-left: 100px;
    margin-right: 100px;
  }

  .sf_growth_bg h4 {
    font-size: 24px;
    padding: 30px 0px 8px 0px;
  }

  .sf_growth_bg p {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }

  .divider-sf {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .sf_growth {
    font-size: 24px;
    margin-top: 50px;
    padding: 0 0px;
  }

  .sf_growth_para {
    font-size: 20px;
  }

  .sf_growth_clo1,
  .sf_growth_clo {
    grid-template-columns: 1fr;
    margin-left: 50px;
    margin-right: 50px;
  }

  .sf_growth_bg h4 {
    font-size: 20px;
    padding: 25px 0px 5px 0px;
  }

  .sf_growth_bg p {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .divider-sf {
    max-width: 95%;
  }
}

@media (max-width: 480px) {
  .sf_growth {
    font-size: 24px;
    margin-top: 40px;
    padding: 0 30px ;
  }

  .sf_growth_para {
    font-size: 14px;
    padding: 0 26px;
  }

  .sf_growth_clo1,
  .sf_growth_clo {
    grid-template-columns: 1fr;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .sf_growth_bg {
    margin: 10px;
  }

  .sf_growth_bg h4 {
    font-size: 18px;
    padding: 20px 0px 5px 0px;
  }

  .sf_growth_bg p {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .divider-sf {
    max-width: 80%;
    margin: 30px auto 0;
  }
}

/* css for container 4 */

.sf_land_service{
    background: radial-gradient(circle at center center, #0682c512, transparent 80%);
}
#sf_land_ser_container{
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
}
.sf_land_ser_col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 120px; 
    margin-right: 120px; 
    margin-top: 80px;
}

.sf_land_ser_col1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 70%;
    justify-self: center;

}

.sf_land_ser_col2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 70%;
    justify-self: center;
    margin-top: 50px;
}

.sf_land_ser_col_bg{
    background-color: #ffffff;
    border-radius: 40px;
    border: 0.1px solid #e1e0e0;
    margin-right:120px;
    padding-bottom: 30px;
}

.sf_land_ser_title{
    display: grid;
    grid-template-columns: 60% 40%;
}

.sf_land_ser_title1{
    display: grid;
    grid-template-columns: 13% 87%;
    margin-left: 90px;
    text-align: justify;
}

.sf_land_ser_para1 p{
    font-size: 24px;
    padding: 0px 40px 0px 100px;
    text-align: left;
    color: #f4f4f4;
    font-weight: 300;
}

.sf_land_ser_title1 img{
    width: 60px;
    height: 60px;
}
.sf_land_ser_title1 h4{
    font-size: 36px;
    margin-top: 5px;
    color: #ffffff;
    line-height: 50px;
    text-align: justify;

}
.sf_land_ser_title1 img{
    width: 50px;
    height: 50px;
}

.sf_land_ser_title h4{
    font-size: 30px;
    margin-top: 20px;
    justify-self: start;
    padding-left: 26px;
    font-weight: 600;
    line-height: 50px;
}

.sf_land_ser_title img{
    justify-self: end;
    width: 100px;
    height: 100px;
    padding: 15px 30px 10px 0px;
}

.sf_land_ser_para p{
  font-size: 24px;
  padding: 0px 65px 0px 26px;
  text-align: left;
}

.sf_land_link{
    display: flex;
    margin: 56px 27px 0;
}

.sf_land_link_3{
  display: flex;
  margin: 120px 27px 0 ;  
}

.sf_land_ser_button{
  align-items: center;
  background-color: #0D65C2;
  color: #ffffff;
  border-radius: 50px;
  font-size: 16px;
  border: none;
  padding: 10px 24px ; 
}

.sf_right_icon1{
    color: #ffffff;
    font-size: 20px;
  }
  
.sf_land_ser_col_next{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 230px; 
    margin-right: 40px; 
    margin-top: 80px;
    margin-bottom: 90px;
}


/* Media Query for Large Screens (Desktops: min-width 1024px) */
@media (max-width: 1450px) and (min-width: 1023px) {
  #sf_land_ser_container {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sf_land_ser_col, 
  .sf_land_ser_col_next {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    gap: 20px;
  }

  .sf_land_ser_col1, .sf_land_ser_col2 {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .sf_land_ser_col_bg{
    margin: 0 0 50px;
  }

  .sf_land_ser_para p {
    font-size: 20px;
    padding: 0px 30px 0px 20px;
  }
  
  .sf_land_ser_button {
    font-size: 14px;
    padding: 8px 20px;
  }

  .sf_land_ser_title h4 {
    font-size: 26px;
    text-align: justify;
  }

  .sf_land_ser_title1 h4 {
    font-size: 30px;
    text-align: justify;

  }

  .sf_land_link{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  }

  .sf_land_link_3{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  }
}

/* Media Query for Tablets (Medium Screens: 768px - 1023px) */
@media (max-width: 1023px) and (min-width: 768px) {
  #sf_land_ser_container {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sf_land_ser_col, 
  .sf_land_ser_col_next {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    gap: 20px;
  }

  .sf_land_ser_col1, .sf_land_ser_col2 {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .sf_land_ser_col_bg{
    margin: 0 0 50px;
  }

  .sf_land_ser_para p {
    font-size: 20px;
    padding: 0px 30px 0px 20px;
  }
  
  .sf_land_ser_button {
    font-size: 14px;
    padding: 8px 20px;
  }

  .sf_land_ser_title h4 {
    font-size: 26px;
    text-align: justify;
  }

  .sf_land_ser_title1 h4 {
    font-size: 30px;
    text-align: justify;

  }

  .sf_land_link{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  }

  .sf_land_link_3{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  }
}

/* Media Query for Mobile Devices (Small Screens: max-width 767px) */
@media (max-width: 767px) {
  #sf_land_ser_container {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sf_land_ser_col, 
  .sf_land_ser_col_next {
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 20px;

  }

  .sf_land_ser_col_bg{
    margin: 0 0 50px;
  }

  .sf_land_ser_col1, .sf_land_ser_col2 {
    grid-template-columns: 1fr;
    max-width: 100%;
    margin-top: 20px;
  }

  .sf_land_ser_para p {
    font-size: 16px;
    padding: 0px 10px;
  }
  
  .sf_land_ser_button {
    font-size: 12px;
    padding: 6px 16px;
  }

  .sf_land_ser_title h4 {
    font-size: 20px;
  }

  .sf_land_link{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  
  }

  .sf_land_link_3{
    display: flex;
    justify-content: end;
    margin: 36px 27px 0;  
  }

  .sf_land_ser_title1 h4 {
    font-size: 22px;
    padding-left: 40px;
    text-align: justify;
  }

  .sf_land_ser_title img, .sf_land_ser_title1 img {
    width: 70px;
    height: 70px;
  }

  .sf_land_ser_title1{
    margin: 10px 0;
  }

  .sf_land_ser_title{
    display: grid;
    grid-template-columns: 70% 30%;
}
}

 /* css for container 5 */

.sf_what{
    color: #ffffff;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;
    font-weight: 700;
    margin-top: 20px;
    font-size: 48px;
}

.sf_what1{
    color: #ffffff;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    font-weight: 700;
}

.sf_what_button1{
  background-color: #ffffff; /* White background */
  color: #0D65C2; /* Blue text color */
  max-width: 500px; /* Maximum width */
  padding: 10px 50px; /* Adjust padding for better spacing */
  border: none; /* Removes border */
  border-radius: 50px; /* Makes it a rounded button */
  text-align: center; /* Centers the text inside the button */
  font-size: 24px; /* Default font size */
  font-weight: 600; /* Slightly bolder text */
  cursor: pointer; 
}
.sf_what_button{
    text-align: center;
}

.sf_land_what_col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* Media Query for Large Screens (Desktops: min-width 1024px) */
@media (min-width: 1024px) {
  .sf_what {
    font-size: 48px;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .sf_what1 {
    font-size: 36px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sf_what_button1 {
    font-size: 24px;
    padding: 10px 50px;
    max-width: 500px;
  }

  .sf_land_what_col {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

/* Media Query for Tablets (Medium Screens: 768px - 1023px) */
@media (max-width: 1023px) and (min-width: 768px) {
  .sf_what {
    font-size: 36px;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .sf_what1 {
    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sf_what_button1 {
    font-size: 20px;
    padding: 8px 40px;
    max-width: 400px;
  }

  .sf_land_what_col {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

/* Media Query for Mobile Devices (Small Screens: max-width 767px) */
@media (max-width: 767px) {


.sf_land_ser_para1 p{
  font-size: 16px;
  padding: 0px 10px 0px 0px;
  text-align: left;
  color: #f4f4f4;
  font-weight: 300;
}

  .sf_what {
    font-size: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sf_what1 {
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sf_what_button1 {
    font-size: 18px;
    padding: 6px 30px;
    max-width: 300px;
  }

  .sf_land_what_col {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}



/* General Styling */
.why-choose-us {
  text-align: center;
  padding: 50px 20px 0px;
  background: radial-gradient(circle at center center, #0d65c236, transparent 80%);
}

.why-choose-us h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 30px auto;
  color: #333;
  padding-top: 20px;
}

.why-choose-us h2 span {
  color: #0D65C2; /* Highlight color */
}

/* Grid Container */
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 60px;
    justify-content: center;
}

/* Grid Items */
.grid-item-lp {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 60px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 500px;
  height: 330px;
  margin-top: 50px;
}

/* Icon Styling */
.icon {
  background-color: #E0E0E0;
  border: none;
  border-radius: 50%;
  width: 111px;
  height: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 25px;
  position: relative;
  top: 70px;
}

/* Text Styling */
.grid-item-lp h3 {
  font-size: 30px;
  margin: 25px;
  color: #000000;
}

.grid-item-lp p {
  font-size: 24px;
  color: #000000;
  line-height: 1.5;
}

/* General Styling */
.why-choose-us {
  text-align: center;
  padding: 50px 20px 0px;
  background: radial-gradient(circle at center center, #0d65c236, transparent 80%);
}

.why-choose-us h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 30px auto 0;
  color: #333;
  padding-top: 20px;
}

.why-choose-us h2 span {
  color: #0D65C2; /* Highlight color */
}

/* Grid Container */
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 60px;
  justify-content: center;
}

/* Grid Items */
.grid-item-lp {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 60px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 500px;
  height: 330px;
  margin-top: 50px;
}

/* Icon Styling */
.icon {
  background-color: #E0E0E0;
  border: none;
  border-radius: 50%;
  width: 111px;
  height: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 25px;
  position: relative;
  top: 70px;
}

/* Text Styling */
.grid-item-lp h3 {
  font-size: 30px;
  margin: 25px;
  color: #000000;
}

.grid-item-lp p {
  font-size: 24px;
  color: #000000;
  line-height: 1.5;
}

/* Media Queries */



@media (max-width: 1650px) and (min-width: 1199px) {
  .grid-container {
    grid-template-columns:auto auto auto;
    gap: 30px;
  }

  .grid-item-lp {
    width: 360px;
    height: 280px;
  }

  .why-choose-us h2 {
    font-size: 40px;
  }

  .grid-item-lp h3 {
    font-size: 21px;
  }

  .grid-item-lp p {
    font-size: 17px;
  }
  .icon{
    width: 90px;
    height: 90px;
    margin: 0 auto 25px;
    position: relative;
    top: 70px;
  }

  .icon img{
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1199px) and (min-width: 888px) {
  .grid-container {
    grid-template-columns:auto auto;
    gap: 40px;
  }

  .grid-item-lp {
    width: 400px;
    height: 300px;
  }

  .why-choose-us h2 {
    font-size: 40px;
  }

  .grid-item-lp h3 {
    font-size: 26px;
  }

  .grid-item-lp p {
    font-size: 20px;
  }
}

/* Small Screens (Mobile) */
@media (max-width:888px ) and (min-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .grid-item-lp {
    width: 100%; /* Full width for mobile */
    height: auto;
    margin-top: 20px;
  }

  .icon {
    width: 90px;
    height: 90px;
    top: 50px;
  }

  .why-choose-us h2 {
    font-size: 32px;
  }

  .grid-item-lp h3 {
    font-size: 22px;
  }

  .grid-item-lp p {
    font-size: 18px;
  }
}

/* Extra Small Screens (Small Mobile) */
@media (max-width: 480px) and (min-width: 320px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .grid-item-lp {
    width: 100%; /* Full width for mobile */
    height: auto;
    margin-top: 20px;
  }

  .icon {
    width: 90px;
    height: 90px;
    top: 50px;
  }

  .why-choose-us h2 {
    font-size: 32px;
  }

  .grid-item-lp h3 {
    font-size: 22px;
  }

  .grid-item-lp p {
    font-size: 18px;
  }
}


.tech-innovations {
  display: grid;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  /* background: linear-gradient(to bottom, #ffffff, #f0f4f8); */
}

.tech-innovations h2 {
  font-size: 48px;
  font-weight: 700;
  color: #262626;
  margin: 30px 0 40px;
}

.tech-innovations h2 span {
  color: #0D65C2;
  font-weight: 700;
}

.icons-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  gap: 30px;
  justify-items: center;
  margin: 25px auto;
  padding: 0 0px;
}

.icon-item {
  text-align: center;
  border-radius: 50%;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon-item:hover {
  transform: translateY(-10px);
}

.icon-item img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.icon-item:nth-child(odd) {
  transform: translateY(-20px);
}

.icon-item:nth-child(even) {
  transform: translateY(20px);
}

/* For screens up to 1200px (large desktops) */
@media (max-width: 1550px) {
  .icons-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    padding: 0 0px;
  }

  .tech-innovations h2 {
    font-size: 42px;
  }

  .icon-item img {
    width: 140px; /* Adjust image size */
    height: 140px;
  }
}
/* For screens up to 1200px (large desktops) */
@media (max-width: 1200px) {
  .icons-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    padding: 0 0px;
  }

  .tech-innovations h2 {
    font-size: 42px;
  }

  .icon-item img {
    width: 140px; /* Adjust image size */
    height: 140px;
  }
}

/* For screens up to 992px (medium desktops/tablets) */
@media (max-width: 992px) {
  .icons-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px;
    padding: 0 80px;
  }

  .tech-innovations h2 {
    font-size: 36px;
  }

  .icon-item img {
    width: 120px; /* Adjust image size */
    height: 120px;
  }
}


@media (max-width: 768px) {

  .tech-innovations h2 {
    font-size: 24px;
    margin: 0 0 20px;
  }

  .icons-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    gap: 2px;
    margin: 0;
    padding: 0;
  }

  .icon-item {
    padding: 5px;
  }

  .icon-item img {
    width: 40px;
    height: 40px;
  }

  .icon-item:nth-child(odd) {
    transform: translateY(-6px);
  }
  
  .icon-item:nth-child(even) {
    transform: translateY(6px);
  }
}

.client_logo_lp{
  background-color: #EFEFEF;
  padding: 0 0 70px;
}

.client_logo_lp h2{
  font-size: 48px;
  font-weight: 700;
  margin: 0px auto;
  text-align: center;
  padding: 70px 0 40px;
}

.client_logo_lp h2 span {
  color: #0D65C2;
  font-weight: 700;
}

#faq-container {
  background-color: #0D65C2;
  padding: 40px 20px 100px;
  color: #ffffff;
}

.faq-title {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}

.faq-list {
  max-width: 1000px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  padding: 20px;
}

.faq-answer {
  font-size: 18px;
  margin: 20px 0;
  line-height: 1.5;
  padding: 0 10px;
}

.faq-arrow {
  font-size: 1.2rem;
}

.faq-line {
  border: none;
  border-top: 1px solid #ffffff;
  margin: 10px 0;
  opacity: 1.5;
}

/* Responsive design */
@media (max-width: 768px) {
  .faq-title {
    font-size: 1.5rem;
  }

  #faq-container {
    background-color: #0D65C2;
    padding: 40px 20px 50px;
    color: #ffffff;
}

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }

  .client_logo_lp h2{
    font-size: 24px;
    padding: 20px 0 30px;
  }

  .client_logo_lp{
    padding-bottom: 35px;
  }


}

.popup_contact_container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 73%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-Index: 1000;
}

.popup_contact{
  background-color: #ffffff;
  padding: 20px;
  border-radius: 50px;
  width: 90%;
  max-width: 900px;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.popup_close{
    position: absolute;
    top: 35px;
    right: 50px;
    background-color: #000000;
    border: none;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    font-size: 40px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
}

.popup_contact h2{
  text-align: center;
  margin: 30px auto;

}

@media (max-width: 768px) {
  .popup_close {
      border-radius: 22px;
      width: 15px;
      height: 29px;
      font-size: 21px;
      
  }
}

