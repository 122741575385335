.contra-section {
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom, rgb(238, 243, 246), rgb(217,236,250)); 

    /* background-image: linear-gradient(180deg, #FFFFFF 0%, #e0eefa 100%); */
    padding: 40px; 
    justify-content: space-evenly;
}


.contra-text {
    flex: 1 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contra-text h1 {
    font-size: 2.2em;
    color: #1B75BB;
}

.contra-text p {
    line-height: 1.6;
    margin: 10px 0;
    text-align: justify;
    font-size: larger;
    color: #555;
}

.contra-image {
    flex: 1 0 0;
    height: 585px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 4px solid #1B75BB;
    border-radius: 0px 175px;
    margin-left: 20px;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.contra-image:hover {
    transform: translateY(-10px) rotate(3deg); 
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); 
}

.contra-image img {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
    transition: opacity 0.3s ease; 
}

.contra-image:hover img {
    opacity: 0.9; 
}

.connect-button {
    margin-top: 20px;
    padding: 15px 20px;
    font-size: 1em;
    color: rgb(255, 255, 255);
    background-color: #1B75BB;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
}

.connect-button:hover {
    background: linear-gradient(32deg, #3030e8 20%, #1b75bb 100%);
    transform: translateY(-2px); 
}

.expertise-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; 
    padding: 40px;
    background-color: #f9f9f9;
}

.expertise-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.expertise-item:hover {
    transform: translateY(-5px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.expertise-item h2 {
    font-size: 1.5em;
    color: #1B75BB;
    margin-bottom: 10px;
}

.expertise-item p {
    color: #555;
    line-height: 1.5;
}

.contratxt2{
    font-size: 3.2em;
    color: #1B75BB;
    text-align: center;
    padding: 20px;
}

.contratxt3{
    font-size: 1.5em;
    text-align: center;
    padding: 10px 20px 20px 20px;
}


@media (max-width: 768px) {
    .contra-section {
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }

    .contra-image {
        width: 100%;
        height: auto;
        margin: 0;
        border-radius: 0px 155px;
    }

    .contra-text h1 {
        font-size: 1.8em;
    }

    .contra-text p {
        font-size: 1em;
    }

    .connect-button {
        font-size: 0.9em;
        padding: 10px 15px;
    }

    .expertise-section {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 20px;
    }

    .contratxt2 {
        font-size: 2em;
    }

    .contratxt3 {
        font-size: 1em;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .contra-section {
        padding: 30px;
    }

    .contra-text h1 {
        font-size: 2em;
    }

    .contra-text p {
        font-size: 1.1em;
    }

    .connect-button {
        font-size: 1em;
        padding: 12px 18px;
    }

    .expertise-section {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 30px;
    }

    .contratxt2 {
        font-size: 2.5em;
    }

    .contratxt3 {
        font-size: 1.1em;
    }
}


@media (min-width: 1025px) {
    .contra-section {
        padding: 40px;
    }

    .contra-text h1 {
        font-size: 2.2em;
    }

    .connect-button {
        font-size: 1em;
        padding: 15px 20px;
    }

    .expertise-section {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 40px;
    }

    .contratxt2 {
        font-size: 3.2em;
    }

    .contratxt3 {
        font-size: 1.2em;
    }
}
