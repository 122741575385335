*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
 .contact-form {
    background-image: url('../assets/images/Contact-BGI.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding: 8% 5% 15% ;
  }
  .contact-form_bg {
    /* background-image: url('../assets/images/Contact-BGI.jpg'); */
    background:linear-gradient(to bottom,  rgb(255, 255, 255), rgb(174, 218, 249));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding: 8% 5% 3% ;
  }

  .headlines{
    max-width: 100%;
  }
  .cont-des{
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    font-family: "Outfit", Sans-serif;
    color: #000000;
  }
  .main-form{
    max-width: 80%;
    margin: 0 auto;
  }

  .contact-form .headline-1{
    text-align: center;
    font-size: 4vw;
    margin-bottom: 10px;
    color: #27589E;
  }
  .contact-form_bg .headline-1{
    text-align: center;
    font-size: 4vw;
    margin-bottom: 10px;
    color: #27589E;
  }
  
  .contact-form .headline-2 {
    text-align: center;
    font-size: 4vw;
    margin-bottom: 40px;
    color: black;
  }
  .contact-form_bg .headline-2 {
    text-align: center;
    font-size: 2vw;
    margin-bottom: 40px;
    color: black;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #33475b;
  }

  .form-group span{
    color:red;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  input[type=text]:focus, 
  input[type=email]:focus,
  input[type=tel]:focus,
  input[type=company]:focus, 
  textarea[type=message]:focus {
    border: 1px solid rgb(149, 149, 255);
  }
  
  .form-group textarea {
    resize: vertical;
    height: 120px;
  }

  .error-input{
    border: 1px solid red !important;
  }

  .error-message{
    color: red;
    font-size: 11px;
    margin-top: 5px;
  }
  
  .s-btn {
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    text-align: center;
    transition: all .15s linear;
    background-color: #0D65C2;
    border-color: #0D65C2;
    color: #fff;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    padding: 12px 24px;
    border-radius: 15px;
  }
  
  .s-btn:hover {
    background-color: #0D65C2;
  }
  .contact-details {
    padding: 0px 20px 50px;
    display: flex;
    align-items: center;
  }

  .con-img{
    width: 30vw;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  
  .contact-head{
    text-align: center;
    font-size: 4vw;
    margin: 20px 0 0 0;
    color: #27589E;
  }
  .co-container {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .grid-1, .grid-2 {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }
  
  .phone, .email {
    display: flex;
    align-items: center;
  }
  
  .s-ico {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    background: #EEDEFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#1b75bb;
    font-size: 20px;
    margin-right: 20px;
  }
  
  .ph, .em {
    font-size:27px;
    font-weight: bold;
  }
  
  .numbers {
    font-size: 20px;
    color: #000000;
  }
  
  .numbers span {
    display: inline-block;
    margin-right: 10px;
  }
  
  .media-icons {
    display: flex;
    gap: 15px;
    margin-top: 60px;
  }
  
  .media-icons .icn {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    border: 1px solid #1b75bb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#1b75bb;
    font-size: 26px;
    margin-right: 5px;
    text-decoration: none;
  }
  
  .spreader {
    height: 100%;
    width: 15px;
    background: linear-gradient(87deg, #1b75bb 57.32%, #6934E9 97.15%);
  }

  .email-id span {
    color: #000000;
    font-size:20px;
    text-decoration: none;
  }
  
  .email-id a {
    text-decoration: none;
  }
  
  .email-id a:hover span {
    text-decoration: underline;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 900px) {
    .co-container {
      grid-template-columns: 1fr;
    }

    .con-img {
      width: 30vw;
    }
  
    .spreader {
      display: none;
    }
  }
  
  /* Mobile View Adjustments (below 768px) */
@media (max-width: 768px) {
    .main-form {
      max-width: 85%; /* Full width for form on mobile */
    }
  
    .contact-form .headline-1,
    .contact-form .headline-2, .contact-head {
      font-size: 5vw; /* Scaled-down headline size */
    }
  
    .form-group input,
    .form-group textarea {
      padding: 8px; /* Adjusted padding for form fields */
      font-size: 14px; /* Reduced font size for inputs */
    }
  
    /* .phone, .email {
      flex-direction: column;
    } */
  
    .numbers {
      font-size: 3.5vw; /* Smaller number font size for mobile */
    }
  
    .media-icons {
      margin-top: 30px; /* Adjusted margin for icons */
      gap: 10px; /* Narrower gap between icons */
    }
  
    .media-icons .icn {
      width: 50px; /* Smaller icon size */
      height: 50px;
      font-size: 20px; /* Reduced icon font size */
    }

    .con-img {
      width: 50vw;
    }
  
    .s-ico {
      width: 50px; /* Smaller icon size */
      height: 50px;
      font-size: 18px;
      margin-right: 10px; /* Reduced margin */
    }
  
    .ph, .em {
      font-size: 5vw; /* Reduced font size for phone/email labels */
    }
  
    .email-id span {
      font-size: 4vw; /* Adjusted email font size for mobile */
    }
  
    .spreader {
      display: none; /* Hide spreader on mobile */
    }
  
    button {
      font-size: 12px; /* Adjust button size */
      padding: 10px 20px;
    }
  
    .contact-details {
      padding: 20px 10px; /* Reduced padding for mobile */
    }
  }
  
  /* Mobile View Adjustments (below 480px) */
  @media (max-width: 480px) {
    .main-form {
      max-width: 90%; /* Full width for form */
    }
  
    .contact-form .headline-1,
    .contact-form .headline-2,.contact-head {
      font-size: 7vw; /* Further reduced headline size for small screens */
    }

    .con-img {
      width: 75vw;
    }
  
    .ph, .em {
      font-size: 6vw; /* Further reduce the font size for labels */
    }
  
    .numbers {
      font-size: 4.5vw; /* Smaller font size for numbers */
    }
  
    .media-icons {
      gap: 8px; /* Narrow gap between icons */
    }
  
    .media-icons .icn {
      width: 40px; /* Smaller icon size for very small screens */
      height: 40px;
      font-size: 16px;
    }
  
    .s-ico {
      width: 40px; /* Further reduce size of icons */
      height: 40px;
      font-size: 16px;
    }
  
    button {
      font-size: 10px; /* Smaller button for mobile */
      padding: 8px 15px;
    }
  
    .email-id span {
      font-size: 5vw; /* Adjust email font size */
    }
  }
  