.accordion1 {
    width: 100%;
    max-width: 600px;
    margin-top: 50px
  }
  
  .accordion-item1 {
    margin-bottom: 10px;
    overflow: hidden;
    border:none;
  }
  
  .accordion-header1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    background-color: #d1ebff;
    border-radius: 19px;
    cursor: pointer;
    color: #1B75BB;
    font-weight: 700;
    font-size: 23px;
   
  }
  
  .accordion-header1 h3 {
    margin: 0;
  }
  
  .accordion-content1 {
    padding: 15px;
    background-color: #d7efff ;
    border-radius: 8px;
    display: none;
    font-size: 20px;
  }
  
  .accordion-content1.active {
    display: block;
  }