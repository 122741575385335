.footer{
    background-color: #171717;
    height: auto;
    color: white;
}

.container{
    max-width:1342px;
}

.footer_menu{
    list-style: none;
    text-align: left;
    margin-left: 170px;
    margin-top:20px;
    color:rgb(189, 187, 187);
}

li{
    margin-top: 7px;
    font-size: 16px;
}

.footer_menu2{
    list-style: none;
    text-align: left;
    margin-left: 80px;
    padding-top: 20px;
}

.footer_icon{
    color: #1B75BB;
    size: 25px;
    margin-right: 10px;  
}

.footer_title{
    margin-top: 90px;
    width: 300px;
    height: auto;
} 

.footer_title1{
    margin-top: 90px;
    margin-left: 200px;
    
}

.footer_title2{
    margin-top: 90px;
    margin-left: 110px;
    /* margin-right: 0px; */
}
.foot-loc{
    padding-left: 110px;
}

.social_media{
    display: flex;
    size: 25px;
    margin-left: 30px; 
}
.social_icon{
    margin-right: 10px;
    
}
.footer_location{
    margin-right: 190px;
}

.footer_location_img1{
    padding-left: 150px;
   
}
.footer_link{
    text-decoration: none;
    color: white;
}

.footer_location_img2{
    
    padding-left: 85px;
    /* margin-right: 50px; */
    text-align: center;
    justify-content: center;
    align-items: center;
}
.location_title{
  text-align: center;
  margin-left: 150px;
  font-size: 18px;
   
}
.location_title1{
    margin-right: 0px;
    text-align: center;
    font-size: 18px;  
}

.location_title-h{
    font-size: 23px;
    padding-left: 234px;
}

.location_title1-h{
    font-size: 23px;
    padding-left: 149px;
}
.copyright{
    font-size: 16px;
    color:#B6BDB9;
    margin-left: 0px;
}

.gduj{
    margin-top: 160px;
}

@media screen and (max-width: 1300px) {
    .footer_title1 {
        margin-top: 90px;
        margin-left: 100px;
    }

    .footer_menu {
        margin-left: 70px;
    }

    .footer_title2{
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        height: auto;
        padding: 20px 10px;
    }

    .footer_menu {
        margin-left: 20px;
    }

    .footer_title1, .footer_title2 {
        margin-left: 30px;
        /* text-align: center;  */
    }

    .social_media {
        justify-content: center;
        margin-left: 0;
    }

    .footer_location_img1, .footer_location_img2 {
        padding-left: 0;
    }

    .location_title1, .location_title {
        margin-left: 0;
    }

    .footer_location {
        margin: 20px 0;
        text-align: center;
    }

    .footer_title{
        margin-top: 50px;
        width: 200px;
        height: auto;
    } 
}

@media screen and (max-width: 600px) {
    .footer_menu {
        margin-left: 10px;
    }
    .footer_menu2{
        padding-top: 20px;
    }
    .footer_title{
        width: 150px;
        height: auto;
    } 
    .footer_title1, .footer_title2 {
        margin-top: 40px;
        font-size: 16px;
    }

    .footer_location_img1, .footer_location_img2 {
        width: 80px;
    }

    .location_title, .location_title1 {
        font-size: 14px;
    }

    .copyright {
        font-size: 14px;
        margin-top: 10px;
    }
}


.footer-mob {
    display: none;
    background-color: #171717;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-grid-mob {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .services-mob{
    text-align: justify;
  }

.connect-mob{
    text-align: justify;
    
}

  
  .services-mob ul,
  .connect-mob ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .services-mob ul li,
  .connect-mob ul li {
    margin-bottom: 10px;
  }
  
  .locations-mob {
    margin-top: 60px;
  }
  .locations-mob h3 {
    margin: 20px;
  }
  
  .locations-grid-mob {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .location-mob {
    text-align: center;
  }
  
  .location-img-mob {
    width: 150px;
    margin-bottom: 10px;
  }
  
  .footer-bottom-mob {
    margin-top: 20px;
  }
  
  .logo-mob img {
    width: 200px;
    margin: 0 auto;
  }
  
  .social-icons-mob {
    margin: 10px 0;
  }
  
  .social-icons-mob a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 20px;
  }
  
  .social-icons-mob a:hover {
    color: #aaa;
  }
  
  .copyright-mob {
    font-size: 12px;
    margin-top: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 900px) {
    .footer{
        display: none;
    }

    .footer-mob{
        display: block;
        padding: 70px 20px;
    }

  }
  @media (max-width: 768px) {
    .footer-grid-mob,
    .locations-grid-mob {
      grid-template-columns:auto auto;
      padding: 0 20px;
    }
  }