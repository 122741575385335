/* General Styling */
  
  .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #eef3f6; /* Light blue */
    padding: 15px 20px;
    position: relative;
  }
  
  /* .navbar-logo img{
    font-size: 1.5rem;
    font-weight: bold;
  } */
  
  .navbar-logo {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .navbar-logo img {
    height: 70px; /* Adjust to your preferred size */
    max-height: 75px; /* Ensures the logo doesn't stretch */
    width: auto; /* Maintains aspect ratio */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .get_started_lp{
    padding: 15px 31px;
    border: none;
    transition: background-color 0.3s ease;
    background: #0D65C2;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 800;
  }
  
  .navbar-logo img:hover {
    transform: scale(1.05); /* Optional: Slight zoom effect on hover */
  }
  
  /* Responsive Adjustment */
  @media (max-width: 768px) {
    .navbar-logo img {
      height: 40px; /* Slightly smaller logo for mobile screens */
    }
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    gap: 70px;
  }
  
  .navbar-links ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;
  }
  
  .navbar-links ul li {
    cursor: pointer;
    position: relative;
  }
  
  .navbar-links ul li a {
    color: rgba(0, 0, 0, 0.685);
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }
  
  .navbar-links ul li a:hover {
    color: 0,0,0;
}
  .navbar-links .lp_ulist {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 60px;
  }
  
  .navbar-links .lp_ulist li {
    cursor: pointer;
    position: relative;
  }
  
  .navbar-links .lp_ulist li a {
    color: rgba(0, 0, 0, 0.685);
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }
  
  .navbar-links .lp_ulist li a:hover {
    color: 0,0,0;
}
  
  /* .contact-btn {
    background-color: white;
    color: #87ceeb;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .contact-btn a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.685);
  } */
  
  .header_cb_1{
    display: none;
  }

  .contact-btn:hover {
    background-color: 0,0,0;
  }
  
  .services-menu .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    width: 220px;
    left: 0;
    background-color: white;
    color: rgba(0, 0, 0, 0.685);
    font-size: 15px;
    list-style: none;
    padding: 10px 0;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .services-menu .dropdown li {
    padding: 3px 15px ;
  }
  
  .services-menu .dropdown li a {
    color: rgba(0, 0, 0, 0.685);
    text-decoration: none;
    font-size: 15px;
  }
  
  .services-menu .dropdown li a:hover {
    color:black;
}
  
  .dropdown.show {
    display: block;
  }
  
  .service_arrow {
    margin-left: 5px;
    font-size: 0.8rem;
    transition: transform 0.3s ease;
  }
  
  .rotate-up {
    transform: rotate(180deg);
  }
  
  .rotate-down {
    transform: rotate(0deg);
  }
  
  /* Mobile Menu Styling */
  .menu-toggle {
    display: none;
    background: none;
    border: 1 rgba(0, 0, 0, 0.505);
    font-size: 1.8rem;
    border-radius: 7px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.505);
    padding: 0px 10px;
  }
  
  .navbar-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #eef3f6; /* Light blue */
    padding: 20px 0;
    z-index: 1000; /* Ensure the menu stays on top */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better visibility */
  }
  .navbar-links.active_1 {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white; /* Light blue */
    padding: 20px 0;
    z-index: 1000; /* Ensure the menu stays on top */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better visibility */
  }
  
  
  .navbar-links.active_1 ul {
    flex-direction: column;
    gap: 15px;
  }
  .navbar-links.active_1 .lp_ulist {
    flex-direction: column;
    gap: 15px;
  }
  
  .navbar-links.active_1 .contact-btn {
    margin-top: 10px;
  }

  .navbar-links.active ul {
    flex-direction: column;
    gap: 15px;
  }
  .navbar-links.active .lp_ulist {
    flex-direction: column;
    gap: 15px;
  }
  
  .navbar-links.active .contact-btn {
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 25px;
      position: relative;
    }

    .navbar-links {
      display: none;
      gap: 20px;
    }

    .header_cb_1{
        display: block;
      }

    .header_cb_2{
        display: none;
      }

    .menu-toggle {
      display: block;
      font-size: 1.5rem;
    }

    .navbar-links.active_1{
      text-align: center;
    }

    .header_cb{
      padding-top: 10px;
    }
  }
  