.team_contanier{
    margin-top: 120px;
    background-image: url('../assets/images/App-bg-img.webp');
    background-repeat: no-repeat;
    /* background-color: #6F3EE9; */
    /* background-color: #2b40dd; */
    height: 500px;
    width: auto;
}
.team_title{
    text-align: center;
    margin-bottom: 0px;
    margin-top: 40px;
}
.team_des{
    padding: 20px;
    text-align: center;
    font-size: 20px;
}
.team_contanier:hover{
    margin-top: 120px;
    background-color: #a9d2f5;
    height: 500px;
}

.team_card{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
}
.card__article{
    position: relative;
   
  }



.team_img{
    height: 425px;
    width: 255px;
    margin-top: -70px;
    border-radius: 150px 150px 20px 20px;
   cursor: pointer;
   filter: grayscale();
    transition: filter 0.4s ease-in-out;
   
}

.team_img:hover {
    filter:none;
}
.card_data{
    background: rgb(255, 255, 255); 
    padding: 30px 20px 20px 20px;
    margin-top: -20px;
    border-radius: 0px 0px 10px 10px;
}
.card_data_overlay {
    position: absolute; 
    bottom: 0; 
    text-decoration: none;
    margin-bottom: -3px;
    border-radius:10px;
    background: rgb(255, 255, 255); /* Black see-through */
    color: #313131; 
    /* width: 72.5%; */
    width: 255px;
    transition: .5s ease;
    opacity:0;
   
    font-size: 16px;
    padding: 20px;
    text-align: center;
    
}
  


.card__article:hover .card_data_overlay{
    opacity: 1;
    
}
.card_title{
    font-size: 20px;
    color: #474747;
    
    
}

@media screen and (max-width: 768px) {
    .team_contanier{
        margin-top: 120px;
        /* background-repeat: no-repeat; */
        /* background-image: url('../assets/images/App-bg-img.webp'); */
        /* background-color: #6F3EE9; */
        height: auto;
        padding-bottom: 30px;
        width: auto;
    }
    .team_contanier:hover{
        margin-top: 120px;
        /* background-color: #cfbefb; */
        height: 1500px;
    }
    .team_img{
        text-align: center;
        height: 400px;
        width: 200px;
        margin-top: 20px;
        border-radius: 150px 150px 20px 20px;
       cursor: pointer;
       filter: grayscale();
        transition: filter 0.4s ease-in-out;
       
    } 
   

    .card_data_overlay {
        position: absolute; 
        bottom: 0; 
        
       
        border-radius:10px;
        background: rgb(255, 255, 255); /* Black see-through */
        color: #313131; 
        width: 200px;
        transition: .5s ease;
        opacity:0;
        /* margin-left: 98px; */
        font-size: 16px;
        padding: 20px;
        text-align: center;
        
    }
    .team_card {
        grid-template-columns: 1fr; /* 1 column for mobile devices */
        justify-items: center; /* Center items horizontally */
    }
}
    

@media screen and (max-width: 320px) {
    .team_card{
        flex-direction: column;
       
    }

    
}

@media screen and (max-width: 480px) {
    .team_card {
        flex-direction: column; /* Stacks cards on top of each other for mobile */
        grid-template-columns: none; /* Removes the grid layout on mobile */
        justify-content: center;
        align-items: center;
    }
    .team_contanier{
        /* background-image: url('../assets/images/App-bg-img.webp'); */
        background-repeat: repeat;
    }
    
}
  