.odoo-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
 
  
}

.odoo_banner_title {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 4vw;
  font-weight: 700;
  z-index: 2;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.odoo-img {
  width: 100%;
  height: auto;
  display: block;
  background-repeat: no-repeat;
  background-color:linear-gradient(to top #000, #000000);
  background-size: cover;
}

.odoo_service_title {
  color: #704A66;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 90px;
}

.odoo_wapp {
  display: flex;
  gap: 15px;
  margin: 20px;
}

.odoo_ser {
  text-align: center;
  max-width: 100%;
  height: auto;
  background-color: #704A66;
  padding-bottom: 50px;
}

.odoo_title {
  padding: 60px 20px;
  color: #FFFF;
  font-size: 60px;
  font-weight: 600;
}

.odoo_container {
  margin: 2%;
  padding: 0%;
  box-sizing: border-box;
}

.odoo_container1 {
  background-color: #9C9C9C;
}

.odoo_card {
  background-color: #fff;
  border-radius: 60px;
  margin-bottom: 20px;
  width: 100%;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  transition: ease-out 0.3s;
}

.odoo_card_title {
  color: #704A66;
}

.odoo_card p {
  font-size: 18px;
}

.odoo_card button {
  background-color: #704A66;
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 10px;
}

.odoo_card_button {
  margin-top: 3.5rem;
}

.font_icon {
  font-size: 60px;
}

.odoo_img {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

.odoo_card_icon {
  margin-right: 10px;
}

.container_odoo_choose {
  display: grid;
  grid-template-columns: 50% 45%;
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 30px;
  gap: 50px;
  width: 100%;
}

.container_odoo_choose h2 {
  color: #704A66;
  margin-top: 6rem;
  font-size: 60px;
  font-weight: 600;
}

.container_odoo_choose p {
  font-size: 18px;
}

.container_odoo_choose button {
  background-color: #704A66;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  margin-top: 2rem;
}

.odoo-grid{
  gap:10px;
  background-color: #ffffff;
  filter: drop-shadow(
    1px 2px 8px var(--shadow-color)
  );
  border-radius: 25px;
  width: 50%;
  padding: 60px 30px;
  text-align: center;
}

.black.odoo-grid {
  --shadow-color: hsl(0deg 0% 0% / 0.25);
}


/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .odoo_banner_title {
    font-size: 6vw;
  }

  .odoo_service_title {
    font-size: 40px;
    margin-top: 60px;
  }

  .odoo_title {
    font-size: 40px;
    padding: 40px 20px;
  }

  .odoo_wapp {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    gap: 10px; /* Reduce the gap */
    margin: 15px; /* Adjust margin for better spacing */
  }

  .odoo-grid {
    width: 100%;
    margin-bottom: 20px;
  }

  .container_odoo_choose {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .container_odoo_choose h2 {
    font-size: 45px;
    margin-top: 4rem;
  }

  .container_odoo_choose p {
    font-size: 16px;
  }

  .container_odoo_choose button {
    margin-top: 1.5rem;
  }

  .odoo_card {
    height: auto;
    padding: 20px;
  }

  .odoo_card_title {
    font-size: 30px;
  }

  .odoo_card p {
    font-size: 16px;
  }

  .container_odoo_choose {
    grid-template-columns: 1fr; /* Stack columns vertically */
    gap: 30px; /* Reduce gap between items */
    margin-left: 5%; /* Increase left margin */
    margin-right: 5%; /* Increase right margin */
  }

  .container_odoo_choose h2 {
    font-size: 45px; /* Decrease heading size */
    margin-top: 4rem; /* Adjust margin for heading */
  }

  .container_odoo_choose p {
    font-size: 16px; /* Decrease paragraph font size */
  }

  .container_odoo_choose img{
    width: 500px;
    height: auto;
  }

  .container_odoo_choose button {
    padding: 8px 15px; /* Reduce button padding */
    margin-top: 1.5rem; /* Adjust button margin */
  }
}

@media (max-width: 480px) {
  .odoo_banner_title {
    font-size: 8vw;
  }

  .odoo_service_title {
    font-size: 30px;
    margin-top: 40px;
  }

  .odoo_title {
    font-size: 35px;
    padding: 30px 15px;
  }

  .container_odoo_choose h2 {
    font-size: 35px;
    margin-top: 3rem;
  }

  .container_odoo_choose p {
    font-size: 14px;
    padding: 20px;
  }

  .container_odoo_choose button {
    margin-top: 1rem;
    padding: 8px 15px;
  }

  .odoo_card {
    height: auto;
    padding: 15px;
    margin-bottom: 15px;
  }

  .odoo_card_title {
    font-size: 24px;
  }

  .odoo_card p {
    font-size: 14px;
  }

  .odoo_img {
    height: 60px;
    width: 60px;
  }

  .font_icon {
    font-size: 40px;
  }
  .container_odoo_choose img{
    width: 300px;
    height: auto;
  }

}
