.blog_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:30;
    padding: 20px;

}
.blog_col{
    border-radius: 20px;
    border: #000 solid 1px;
    margin: 20px;
}
.blog_content{
    padding: 30px;
}

.blog_head{
    text-align: center;
    color:#27589E;
    padding: 30px;
}
.blog_title{
    text-transform: capitalize;
    font-size: 22px;
    color:#27589E;
}
.blog_button{
    background-color: #27589E;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 8px;
}
.blog_detail{
   width: 85%;
   height: auto;
   margin-left: 40px;
   margin-right: 40px;
   padding-top: 40px;
}
